@charset "UTF-8";

// Default Variables

$slick-font-path: "../fonts";
$slick-font-family: "Flaticon" !default;
$slick-loader-path: "../images/" !default;
$slick-arrow-color: white !default;
$slick-dot-color: #5FB3FF !default;
$slick-prev-character: "\e009" !default;
$slick-next-character: "\e008" !default;
$slick-dot-character: "\e042" !default;
$opacity-default: 0.75;
$opacity-on-hover: 1;
$opacity-not-active: 0.25;

@function slick-image-url($url) {
   @if function-exists(image-url) {
      @return image-url($url, false, false);
   }
   @else {
      @return url($slick-loader-path + $url);
   }
}

@function slick-font-url($url) {
   @if function-exists(font-url) {
      @return font-url($url);
   }
   @else {
      @return url($slick-font-path + $url);
   }
}

/* Slider */

.slick-slider {
   position: relative;
   display: block;
   box-sizing: border-box;
   -moz-box-sizing: border-box;
   -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   -ms-touch-action: none;
   touch-action: none;
   -webkit-tap-highlight-color: transparent;
}

.slick-list {
   position: relative;
   overflow: hidden;
   display: block;
   margin: 0;
   padding: 0;
   &:focus {
      outline: none;
   }
   .slick-loading & {
      background: white slick-image-url("ajax-loader.gif") center center no-repeat;
   }
   &.dragging {
      cursor: pointer;
      cursor: hand;
   }
}

.slick-slider .slick-list,
.slick-track,
.slick-slide,
.slick-slide img {
   -webkit-transform: translate3d(0, 0, 0);
   -moz-transform: translate3d(0, 0, 0);
   -ms-transform: translate3d(0, 0, 0);
   -o-transform: translate3d(0, 0, 0);
   transform: translate3d(0, 0, 0);
}

.slick-track {
   position: relative;
   left: 0;
   top: 0;
   display: block;
   zoom: 1;
   &:before,
   &:after {
      content: "";
      display: table;
   }
   &:after {
      clear: both;
   }
   .slick-loading & {
      visibility: hidden;
   }
}

.slick-slide {
   float: left;
   height: 100%;
   min-height: 1px;
   img {
      display: block;
   }
   &.slick-loading img {
      display: none;
   }
   display: none;
   &.dragging img {
      pointer-events: none;
   }
   .slick-initialized & {
      display: block;
   }
   .slick-loading & {
      visibility: hidden;
   }
   .slick-vertical & {
      display: block;
      height: auto;
      border: 1px solid transparent;
   }
}

/* Icons */

// @if $slick-font-family == "slick" {
//   @font-face {
//       font-family:"slick";
//       src:    url("../fonts/slick.eot");
//       src:    url("../fonts/../fonts/slick.eot?#iefix") format("embedded-opentype"),
//               url("../fonts/slick.woff") format("woff"),
//               url("../fonts/slick.ttf") format("truetype"),
//               url("../fonts/slick.svg#slick") format("svg");
//       font-weight: normal;
//       font-style: normal;
//   }
// }

/* Arrows */

.slick-prev,
.slick-next {
   position: absolute;
   display: block;
   height: 80px;
   width: 50px;
   line-height: 0;
   font-size: 0;
   cursor: pointer;
   background: transparent;
   color: transparent;
   bottom: 35%;
   margin: 0;
   padding: 0;
   border: none;
   outline: none;
   // text-indent: -12px;
   &:hover, &:focus {
      outline: none;
      background: transparent;
      color: transparent;
      &:before {
         opacity: $opacity-on-hover;
         //added to fix duplicated hover instance
         // display: block;
         // height: 80px;
         // width: 50px !important;

      }
   }
   &.slick-disabled:before {
      opacity: $opacity-not-active;
   }
}

.slick-prev:before, .slick-next:before {
   display: block;
   position: absolute;
   font-family: $slick-font-family;
   font-size: 50px;
   margin-left: -12px;
   line-height: 1;
   top: 0;
   left: 0;
   height: 80px;
   width: 50px !important;
   color: $slick-arrow-color;
   opacity: $opacity-default;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
   left: 0px;
   &:before {
      content: $slick-prev-character;
   }
}

.slick-next {
   right: 0px;
   &:before {
      content: $slick-next-character;
   }
}

/* Dots */

.slick-slider {
   margin-bottom: 0px;
}

.slick-dots {
   position: absolute;
   bottom: -45px;
   list-style: none;
   display: block;
   //text-align: center;
   padding: 0;
   width: 100%;
   margin-left: 0;
   li {
      position: relative;
      display: inline-block;
      height: 20px;
      width: 20px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;
      button {
         border: 0;
         background: transparent;
         display: block;
         height: 20px;
         width: 20px;
         outline: none;
         line-height: 0;
         font-size: 0;
         color: transparent;
         padding: 5px;
         cursor: pointer;
         &:hover, &:focus {
            outline: none;
            &:before {
               opacity: $opacity-on-hover;
            }
         }
         &:before {
            position: absolute;
            top: 0;
            left: 0;
            content: $slick-dot-character;
            width: 20px;
            height: 20px;
            font-family: $slick-font-family;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            color: $slick-dot-color;
            opacity: $opacity-not-active;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
         }
      }
      &.slick-active button:before {
         opacity: $opacity-default;
      }
   }
}

@charset "utf-8";
@import "settings";
@import "assets/vendor/zurb/foundation/scss/foundation";
// @import "assets/components/motion-ui/motion-ui";
@import "flaticon";
@import "slick";
@import "slick-lightbox";
// Or selectively include components
@include foundation-global-styles; //@include foundation-grid;
@include foundation-flex-grid;
//@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms; // @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
//@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
//@include foundation-orbit;
@include foundation-pagination;
//@include foundation-progress-bar; //@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
//@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes; //@include foundation-float-classes;
@include foundation-flex-classes;
// @include foundation-prototype-classes;
// @include motion-ui-transitions;
// @include motion-ui-animations;
// FOR IE 10
@-ms-viewport {
  width: device-width;
}
// FONTS
//////////////////////////////////////////////////////////////////////////
//@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap');
@font-face {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: local('Lato'),
  url('../fonts/lato-light.woff2') format('woff2'),
  url('../fonts/lato-light.woff') format('woff'),
  url('../fonts/lato-light.ttf') format('ttf');
  font-style: normal;
}
@font-face {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: local('Lato'),
  url('../fonts/lato-regular.woff2') format('woff2'),
  url('../fonts/lato-regular.woff') format('woff'),
  url('../fonts/lato-regular.ttf') format('ttf');
  font-style: normal;
}
@font-face {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-display: swap;
  src: local('Lato'),
  url('../fonts/lato-bold.woff2') format('woff2'),
  url('../fonts/lato-bold.woff') format('woff'),
  url('../fonts/lato-bold.ttf') format('ttf');
  font-style: normal;
}
@font-face {
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  font-display: swap;
  src: local('Lato'),
  url('../fonts/lato-black.woff2') format('woff2'),
  url('../fonts/lato-black.woff') format('woff'),
  url('../fonts/lato-black.ttf') format('ttf');
  font-style: normal;
}
// MIXINS
//////////////////////////////////////////////////////////////////////////
@function f-header-size($breakpoint, $size) {
  @return rem-calc(map-get(map-get(map-get($header-styles, $breakpoint), $size), "font-size") * 1px);
}
@mixin header-size($type) {
  font-size: rem-calc(map-get(map-get(map-get($header-styles, "small"), $type), "font-size") * 1px);
  @include breakpoint(medium) {
    font-size: rem-calc(map-get(map-get(map-get($header-styles, "medium"), $type), "font-size") * 1px);
  }
}
/* #### Generated By: http://www.cufonfonts.com #### */
@font-face {
  font-family: 'Abadi';
  font-display: swap;
  font-weight: normal;
  src: local('Abadi MT Std'), url('../fonts/abadimtstd-opentype.woff') format('woff');
  font-style: normal;
}
//
//
//@font-face {
//  font-family: 'Abadi';
//  font-display: swap;
//  font-style: normal;
//  font-weight: 200;
//  src: local('Abadi MT Std Extra Light'), url('abadimtstd-extralight-opentype.woff') format('woff');
//}
//
//
//@font-face {
//  font-family: 'Abadi';
//  font-display: swap;
//  font-style: normal;
//  font-weight: normal;
//  src: local('Abadi MT Std Extra Light Italic'), url('abadimtstd-extralightit-opentype.woff') format('woff');
//}
@font-face {
  font-family: 'Abadi';
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/abadimtstd-light-opentype.woff') format('woff');
}
@font-face {
  font-family: 'Abadi';
  font-display: swap;
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/abadimtstd-lightitalic-opentype.woff') format('woff');
}
@font-face {
  font-family: 'Abadi';
  font-display: swap;
  font-style: italic;
  font-weight: normal;
  src: url('../fonts/abadimtstd-italic-opentype.woff') format('woff');
}
@font-face {
  font-family: 'Abadi';
  font-display: swap;
  font-style: bold;
  font-weight: normal;
  src: url('../fonts/abadimtstd-bold-opentype.woff') format('woff');
}
@font-face {
  font-family: 'Abadi';
  font-display: swap;
  font-style: italic;
  font-weight: bold;
  src: url('../fonts/abadimtstd-bolditalic-opentype.woff') format('woff');
}
@font-face {
  font-family: 'Abadi';
  font-display: swap;
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/abadimtstd-extrabold-opentype.woff') format('woff');
}
@font-face {
  font-family: 'Abadi';
  font-display: swap;
  font-style: italic;
  font-weight: 800;
  src: url('../fonts/abadimtstd-extraboldit-opentype.woff') format('woff');
}
@mixin stak {
  // defaults;
  padding: 20px 1em;
  p, ul {
    font-size: 1rem;
    &.lead {
      font-size: 100%;
      line-height: 1.6;
    }
  }
  @include breakpoint(medium) {
    // enhancements;
    padding: 40px 2em;
  }
  @include breakpoint(large) {
    // enhancements;
    padding: 40px 2em;
  }
  @include breakpoint(xlarge) {
    // enhancements;
    padding: 60px 0;
    p, ul {
      font-size: 1.15rem;
      &.lead {
        font-size: 105%;
        line-height: 1.6;
      }
    }
    li {
      margin-bottom: 1em;
    }
  }
}
@mixin hero-slide {
  // defaults;
  min-height: 500px;
  background-repeat: no-repeat;
  background-size: cover !important;
  z-index: 0;
  overflow: hidden;
  background-position: center center;
  backface-visibility: hidden;
  align-items: center;
  display: flex;
  @include breakpoint(medium) {
    // enhancements;
    height: 500px;
  }
  @include breakpoint(large) {
    // enhancements;
    height: 600px;
  }
  @include breakpoint(xlarge) {
    // enhancements;
    height: 600px;
  }
}
@mixin no-bullet {
  list-style-type: none;
  margin: 0;
}
@mixin stak-pic {
  @include stak;
  display: table;
  width: 100%;
  min-height: 300px;
  .row {
    display: table-row;
    width: 100%;
  }
  .columns {
    display: table-cell;
    float: none;
    vertical-align: middle;
    text-align: center;
  }
  .column:last-child:not(:first-child),
  .columns:last-child:not(:first-child) {
    float: none;
  }
  .vertical-center {
    vertical-align: middle;
  }
  h2 {
    //color: $white;
    line-height: initial;
    span {
      background-color: rgba($body-font-color, 0.8);
      padding: 0 0.25em;
      margin: 0;
      box-decoration-break: clone;
      -webkit-box-decoration-break: clone;
    }
    strong {
      color: $black;
      display: inline-block;
    }
  }
  h3,
  li,
  p,
  strong {
    //color: $white;
  }
  @include breakpoint(large) {
    background-attachment: fixed;
  }
}
@mixin fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  left: 0;
  &.expanded:not(.top-bar) {
    height: auto;
    max-height: 100%;
    overflow-y: auto;
    width: 100%;
  }
  &.expanded:not(.top-bar) .title-area {
    position: fixed;
    width: 100%;
    z-index: 99;
  }
  &.expanded:not(.top-bar) .top-bar-section {
    margin-top: 5rem;
    z-index: 98;
  }
}
@mixin radius {
  border-radius: 3px;
}
@mixin box-shadow {
  box-shadow: 0px 10px 20px -15px rgba(0, 0, 0, .5);
}
.foo {
  @include grid-row;
  .bar {
    @include grid-column(12);
  }
}
@media screen and #{breakpoint(medium)} {
  .foo {
    @include grid-row;
    .bar {
      @include grid-column(4);
    }
  }
}
@mixin overlay {
  content: "";
  background: rgba($black, .5);
  background: linear-gradient(90deg, rgba($dark-gray, .75) 0%, rgba($dark-gray, .25) 100%);
  position: absolute;
  mix-blend-mode: normal;
  opacity: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  height: 100%;
  width: 100%;
}
@mixin overlay-white {
  content: "";
  background: rgba($black, .5);
  background: linear-gradient(90deg, rgba($white, .9) 0%, rgba($white, .9) 50%, rgba($white, .15) 100%);
  position: absolute;
  mix-blend-mode: normal;
  opacity: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  height: 100%;
  width: 100%;
}
@mixin overlay-cyan {
  @include overlay-white;
  background: rgba($light-blue, .5);
  background: linear-gradient(90deg, rgba($light-blue, 1) 0%, rgba($light-blue, .25) 100%);
}
@mixin overlay-red {
  @include overlay-white;
  //background: rgba($primary-color, .5);
  background: linear-gradient(90deg, rgba($primary-color, 1) 0%, rgba($primary-color, .25) 100%);
}
@mixin overlay-navyblue {
  @include overlay-white;
  background: rgba($navy-blue, .5);
  background: linear-gradient(90deg, rgba($navy-blue, 1) 0%, rgba($navy-blue, .25) 100%);
}
@mixin overlay-blue {
  @include overlay-white;
  background: rgba($blue, .5);
  background: linear-gradient(90deg, rgba($blue, 1) 0%, rgba($blue, .25) 100%);
}
@mixin overlay-pattern {
  content: "";
  position: absolute;
  mix-blend-mode: normal;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-image: url("../images/bgd-angles-pattern.webp");
  opacity: .8;
}
// DEFAULTS (* see _settings.scss for FOUNDATION OVERRIDES)
//////////////////////////////////////////////////////////////////////////
$logo-small: 80px;
$logo-medium: 120px;
$logo-large: 150px;
address {
  font-style: normal;
  margin-bottom: 2em;
  strong {
    color: $black;
  }
}
.columns {
  position: relative;
}
.fixed {
  position: fixed;
  width: 100%;
  z-index: 1;
}
$off-canvas-width: 60%;
.row.expanded {
  max-width: none;
  > .columns {
    padding: 0;
  }
}
:focus {
  outline: none;
}
a.has-tip {
  border-bottom: none;
  color: $primary-color;
  cursor: pointer;
  font-weight: normal;
}
.slick-arrow {
  z-index: 1;
}
.more {
  @include button;
  //@include header-size(h5);
  margin-top: 1em;
  color: $white !important;
  overflow: hidden;
  //width: 250px;
  cursor: pointer;
  white-space: nowrap;
  transition-timing-function: linear;
  transition: all 0.2s ease;
  margin: 0 auto 1em;
  position: relative;
  border: none !important;
  span {
    display: none;
  }
  &:before {
    content: '';
    background: $primary-color url("../images/arrowhead.png") center center no-repeat;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition-timing-function: linear;
    transform: translateZ(0) scale(1, 1.1) translate(-101%, 0);
    transition: all 0.2s ease-in-out 0s;
  }
  &:hover:before {
    transform: translateZ(0) scale(1, 1.1) translate(0%, 0);
  }
}
.connect-button {
  @include button;
  //@include header-size(h5);
  margin-top: 1em;
  color: $white !important;
  overflow: hidden;
  //width: 250px;
  cursor: pointer;
  white-space: nowrap;
  transition-timing-function: linear;
  transition: all 0.2s ease;
  margin: 0 auto 1em;
  position: relative;
  border: none;
  &:before {
    @include button;
    content: 'send email';
    background: $primary-color;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition-timing-function: linear;
    transform: translateZ(0) scale(1, 1.1) translate(-101%, 0);
    transition: all 0.2s ease-in-out 0s;
  }
  &:hover:before {
    transform: translateZ(0) scale(1, 1.1) translate(0%, 0);
  }
}
ul.link-list {
  list-style: none;
  margin: 0 auto;
  li:after {
    content: "\203A \20";
    padding-left: 0.25em;
  }
  li:hover:after {
    color: $orange;
  }
  a {
    display: inline-block;
    &:after {
      content: '';
      display: block;
      height: 3px;
      width: 0;
      margin: 0.25em 0 0;
      background: transparent;
      transition: width 0.25s ease, background-color 0.25s ease;
    }
    &:hover:after {
      width: 100%;
      background: $orange;
    }
  }
}
ul.row {
  list-style: none;
  li {
    margin: 0.5em 0;
    line-height: 1.25em;
  }
}
.stak-pic {
  @include stak-pic;
}
.bottom-shadow {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  background: linear-gradient(180deg, rgba($gray, 0) 98%, rgba($gray, 0.65) 100%);
  //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
  // box-shadow:  0 0 3px 2px rgba($white,0.9);
  box-shadow: 0px 50px 50px 50px rgba(255, 255, 255, 1) inset;
  // box-shadow: 0px -7px 5px 0px rgba($light-gray, .75) inset;
  &.split-image {
    img {
      box-shadow: none !important;
      border: none !important;
      border-radius: initial;
      mix-blend-mode: multiply;
    }
  }
}
// prevent mobile no-scroll
.slick-slider {
  touch-action: auto;
  -ms-touch-action: auto;
}
small {
  font-weight: normal;
  display: block;
  margin-bottom: .5em;
  margin-top: .5em;
  text-transform: uppercase;
  font-size: .4em;
}
// headings
h1, h2, h3 {
  font-weight: 900;
}
h2 {
  color: $blue;
}
h3 {
  color: $blue;
  sup {
    position: relative;
    font-size: 40%;
    line-height: 0;
    vertical-align: baseline;
    top: -1.1em;
  }
}
a {
  h1, h2, h3 {
    color: $body-font-color;
    transition: all .25s;
  }
  &:hover {
    h1, h2, h3 {
      color: $secondary-color;
    }
  }
}
.button {
  font-weight: 400;
  &.hollow {
    border-width: 2px;
  }
}
ul.no-bullet {
  @include no-bullet;
  ul {
    @include no-bullet;
  }
}
// GLOBALS (CUSTOM GLOBALS)
//////////////////////////////////////////////////////////////////////////
.skip-link.screen-reader-text {
  display: none;
}
.sticky-container {
  transition: all 0.25s;
  z-index: 999; // for dropdown pre-scroll
}
header.main-nav {
  background-color: rgba($white, 0.95);
  transition: all 0.25s;
  position: relative;
  filter: drop-shadow(0 5px 5px rgba($light-gray, 1));
  .logo {
    width: $logo-small;
    margin: 1em 0;
    transition: all 0.25s;
  }
  @include breakpoint(medium) {
    .logo {
      width: $logo-medium;
    }
  }
  @include breakpoint(large) {
    // enhancements;
    .logo {
      width: $logo-large;
    }
  }
  @include breakpoint(xlarge) {
    // enhancements;
    // width: 100%;
    // position: fixed;
    // z-index: 2;
    // top: 0;
  }
  // GLOBAL-MENU ///////////////////////////////////////////////
  ul.global-menu {
    display: inline-block;
    vertical-align: baseline;
    align-self: flex-end;
    width: 100%;
    align-items: flex-end;
    text-align: right;
    > li {
      position: relative;
      text-align: left;
    }
    > li > a {
      font-size: 1.1em;
      padding: 0 1.25em;
      color: $black;
      line-height: 3em;
      //text-transform: uppercase;
      font-weight: 600;
      transition: all 0.5s;
      position: relative;
      text-align: left;
      &:last-child {
        padding-right: 0;
      }
      &:hover {
        color: $secondary-color;
      }
      &:after {
        content: '';
        display: block;
        left: 0;
        height: 3px;
        width: 0;
        background: $primary-color;
        transition: width 0.5s ease, background-color 0.25s ease;
      }
      &.hover:after,
      &:hover:after {
        width: 100%;
        background: $primary-color;
      }
      &.sel {
        color: $secondary-color;
        &:after {
          width: 100%;
          background: $secondary-color;
        }
      }
    }
    @include breakpoint(large) {
      > li > a {
        font-size: 1.25em;
        padding: 0 2em;
      }
    }
    @include breakpoint(xlarge) {
      // enhancements;
      > li > a {
        font-size: 1.35em;
        padding: 0 3em;
      }
    }
  }
  .dropdown-pane {
    text-align: left;
    &.mega-menu {
      width: 100% !important;
      border: none;
      background-color: transparent;
      padding: 0;
      &.is-open {
        left: 0 !important;
        display: block;
        z-index: 999999;
      }
      .content {
        background-color: $white;
        padding: 1.25em;
        border: 1px solid $light-gray;
        h4 {
          font-weight: bold;
          color: $black;
        }
        a {
          display: inline-block;
          line-height: 1.25em;
          &:hover {
            color: $secondary-color;
          }
        }
        .description {
          padding-top: 0.5em;
          text-align: right;
          p {
            line-height: 1.5em;
            font-size: 1.1em;
          }
        }
        .nav-title {
          @include header-size(h5);
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 0.05em;
          a {
            color: $gray;
          }
        }
        .menu .button,
        .menu a {
          line-height: 1;
          text-decoration: none;
          display: block;
          padding: 0.7rem 1rem 0.7rem 0;
          font-weight: normal;
          @include breakpoint(large) {
            font-size: 1.15em;
          }
        }
        &:not(.vertical) {
          li {
            margin-right: 2em;
          }
        }
        .menu-ico {
          max-width: 30px;
          display: inline;
          padding-right: 6px;
          line-height: 1em;
          margin: 0;
        }
      }
      img {
        width: 100%;
        margin: 0.75em 0;
        display: block;
      }
    }
    .vertical.menu {
      a {
        &:hover {
          text-decoration: underline;
          color: scale-color($anchor-color, $lightness: -40%);
        }
      }
    }
  }
}
.is-stuck {
  header.main-nav {
    background-color: rgba($white, 0.95);
    height: auto;
    @include breakpoint(large) {
      // enhancements;
      .logo {
        width: $logo-medium;
        margin: .25em 0;
      }
    }
  }
  .ancillary {
    margin-top: -40px;
  }
}
// ancillary
.ancillary {
  text-align: right;
  display: block;
  background: $silver;
  transition: all .5s;
  ul.ancillary-menu {
    margin: 0 !important;
    font-size: 0.8em;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    list-style-type: none;
    li {
      list-style-type: none;
      padding: 0 0 0 6px;
      &:last-of-type {
        padding: 0;
      }
      a {
        color: $black;
        &:hover {
          color: $secondary-color;
        }
      }
      form {
        display: inline;
      }
      .input-group {
        border: 1px solid $white;
      }
      .search-button {
        font-size: 1em;
        padding: 0 .5em 0 1em;
        color: $white;
        // line-height: 4em;
        text-transform: uppercase;
        font-weight: 600;
        position: relative;
        text-align: left;
        display: block;
        cursor: pointer;
        color: $secondary-color;
        position: relative;
        height: 36px;
        &:after {
          position: absolute;
          content: '';
          display: block;
          bottom: 0;
          left: 0;
          height: 6px;
          width: 0;
          color: $primary-color;
          transition: width 0.5s ease, background-color 0.25s ease;
        }
        &:hover, &:focus {
          color: $primary-color;
        }
        &.hover {
          background-color: $black;
          color: $white;
        }
        @include breakpoint(xxlarge) {
          font-size: 1.25em;
        }
      }
    }
    .search-pane {
      background-color: $black;
      filter: drop-shadow(0px 5px 5px rgba($black, .25));
      // filter: drop-shadow(0 5px 5px rgba($light-gray, 1));
      border: none;
      padding-bottom: 0;
      input {
        background-color: $black;
        border-right: 1px solid $white;
        color: $white;
        box-shadow: none;
        font-weight: bold;
        &::placeholder {
          color: $gray;
        }
      }
    }
    .icon-search {
      padding: 0.7rem 0rem;
      &:before {
        font-family: Flaticon;
        font-size: 1.25em;
        font-style: normal;
        content: "\e015";
      }
    }
    .social-icon {
      font-size: 1.5em;
      padding: 0 0.5rem 0 0;
    }
    .flaticon-youtube13 {
      &:before {
        margin-top: 4px;
        display: block;
      }
    }
    .button {
      color: $white;
      font-weight: 400;
      border: none;
      &:hover {
        color: $white;
        background-color: $primary-color;
      }
    }
  }
  .phone {
    font-weight: bold;
  }
  .contact {
    @include button;
    @include button-hollow;
    font-size: 1em;
    border: lighten($secondary-color, 20%) 1px solid;
    color: $secondary-color;
    transition: all 0.25s;
    margin: 0;
    a {
      color: $secondary-color;
    }
    &:hover {
      border: $secondary-color 1px solid;
    }
  }
  // .x-collapse {
  //    .columns,
  //    .postfix,
  //    a,
  //    button,
  //    input {
  //       margin: 0 !important;
  //    }
  // }
  // input:focus {
  //    outline: none;
  // }
  // .search {
  //    background-color: transparent;
  //    border-color: transparent;
  //    border: none;
  //    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0);
  //    width: 30px;
  //    height:30px;
  //    background: url("../images/search.png") no-repeat scroll 4px center transparent;
  //    float: right;
  //    color: $gray;
  //    padding: 4px;
  //    margin-right: 10px;
  //    text-indent: 30px;
  //    font-size: 1em;
  //    border-radius: 4px;
  //    cursor: pointer;
  //    &::-webkit-input-placeholder {
  //       color: lighten($gray, 20%);
  //    }
  // }
  // .open {
  //    background-color: $white;
  // }
}
// MOBILE NAVIGATION
/////////////////////////////////////
.title-bar {
  text-align: center;
  padding: 0.5em 0 0.25em;
  line-height: 1em;
  .logo {
    width: $logo-small;
    margin: 0 auto;
    padding: 0;
    .fill-black {
      fill: $graphite;
    }
  }
  .menu-ico {
    //margin: -2px 0.3em;
    border: none;
    background: none;
    z-index: 99999;
    transform: scale3d(0.8, 0.8, 0.8);
    transition: 0.3s;
    cursor: pointer;
    user-select: none;
    position: absolute;
    right: 10px;
    top: 1em;
    span {
      display: inline-block;
      width: 2.5rem;
      height: 0.26rem;
      background: $secondary-color;
      border-radius: 0.5em;
      transition: 0.3s;
      position: relative;
    }
    span:before {
      display: inline-block;
      width: 2.5rem;
      height: 0.26rem;
      background: $secondary-color;
      border-radius: 0.5em;
      transition: all 0.3s;
      position: absolute;
      top: 0.75em;
      left: 0;
      content: '';
      transform-origin: 0.13rem center;
    }
    span:after {
      display: inline-block;
      width: 2.5rem;
      height: 0.26rem;
      background: $secondary-color;
      border-radius: 0.5em;
      transition: all 0.3s;
      position: absolute;
      top: -.75em;
      left: 0;
      content: '';
      transform-origin: 0.13rem center;
      //  box-shadow: 0 0 0 1px $white, 0 7px 0 1px $white, 0 14px 0 1px $white;
    }
    &:hover {
      span {
        background: lighten($secondary-color, 10%);
      }
      span:before {
        background: lighten($secondary-color, 10%);
      }
      span:after {
        background: lighten($secondary-color, 10%);
        //  box-shadow: 0 0 0 1px $white, 0 7px 0 1px $white, 0 14px 0 1px $white;
      }
    }
  }
}
.menu-ico[aria-expanded="true"] {
  //background-color: $primary-color;
  span {
    background: transparent !important;
  }
  span:before {
    //box-shadow: 0 0 0 1px $white, 0 7px 0 1px $white, 0 14px 0 1px $white;
    transform: rotate3d(0, 0, 1, 45deg);
    transform-origin: 50% 50%;
    top: 0;
    background-color: $secondary-color;
  }
  span:after {
    //box-shadow: 0 0 0 1px $white, 0 7px 0 1px $white, 0 14px 0 1px $white;
    transform: rotate3d(0, 0, 1, -45deg);
    transform-origin: 50% 50%;
    top: 0;
    background-color: $secondary-color;
  }
}
.right-off-canvas-menu {
  padding-top: 3.75rem;
  //background-color: rgba($jet, .9);
  .menu li a {
    display: block;
    border-bottom-color: darken($light-gray, 10%);
    border-top: 1px solid rgba($light-gray, 0.15);
    color: $dark-gray;
    &:hover {
      color: $secondary-color;
    }
  }
  .menu li:first-of-type {
    a {
      border-top: none;
    }
  }
  .menu li:last-of-type {
    a {
      border-bottom: none;
    }
  }
  .icon-search {
    padding: 0.7rem 0rem;
    &:before {
      font-family: Flaticon;
      font-size: 1.25em;
      font-style: normal;
      content: "\e015";
    }
  }
}
// GLOBAL FOOTER
/////////////////////////////////////
footer.global-footer {
  background-color: $secondary-color;
  padding: 2em 0 0;
  font-size: 1em;
  *:not(.talk-btn) {
    color: $white;
  }
  text-align: center;
  .logo {
    vertical-align: middle;
    margin: 0 auto;
    max-width: $logo-small;
  }
  ul.menu {
    @include no-bullet;
    text-align: left !important;
    margin-bottom: 1em;
    a {
      transition: all .25s;
      padding: 0.25rem 0rem;
      &:hover {
        // color: $light-blue;
        color: $black;
      }
    }
    li {
      display: inline-block;
      position: relative;
      padding-right: .75em;
      &:after {
        content: "|";
        position: absolute;
        width: 2px;
        right: 0;
        top: 0;
        padding-right: .5em;
      }
      &:last-of-type {
        &:after {
          display: none;
        }
      }
    }
    li:first-of-type {
      display: block;
      &:after {
        display: none;
      }
    }
    h5 {
      margin: 0;
      font-weight: 400;
      a {
        //color: $black;
      }
    }
  }
  .copyright {
    margin: .5em auto 0 auto;
    padding: .5em 0;
    min-width: 100%;
    text-align: center;
    background-color: $black;
    color: $medium-gray;
    p {
      font-size: 1em;
      margin: 0;
      //color: $white;
      vertical-align: middle;
    }
  }
  ul.social-icons {
    font-size: 1.25em;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    li {
      padding: 0 0 0 6px;
      &:last-of-type {
        padding: 0;
      }
      a {
        //margin-left: -.5em;
        //margin-right: 0.5em;
        height: 40px;
        padding: 0;
        width: 40px;
        &:nth-of-type(2) {
          line-height: 1.25em;
        }
      }
      .flaticon-youtube13 {
        &:before {
          margin-top: 4px;
          display: block;
        }
      }
    }
    @include breakpoint(medium) {
      justify-content: flex-start;
      li {
        padding: 0 8px 0 6px;
        &:last-of-type {
          padding: 0 0 0 6px;
        }
      }
    }
    [class^="flaticon-"]:hover:after,
    [class*="flaticon-"]:hover:before {
      color: $black;
    }
  }
  .telephone {
    display: block;
    margin: 0.5em 0;
  }
  .talk-btn {
    @include button($expand: true, $background: $white, $background-hover: $primary-color, $color: $secondary-color);
    transition: all, 0.25s;
    font-weight: 400;
    @include radius;
    @include header-size(h5);
    &:hover {
      color: $white;
    }
  }
  @include breakpoint(medium) {
    font-size: 1.1em;
    text-align: left;
    h5 {
      margin-top: 0;
    }
    .logo {
      margin: 0;
    }
    ul.menu {
      a {
        padding: 0.7rem 1rem;
        line-height: 1.15em;
      }
      li {
        display: block;
        padding: 0;
        &:after {
          content: none !important;
        }
      }
    }
  }
}
.icon-callouts {
  img {
    padding-right: 0.5em;
  }
  a:hover {
    h4 {
      color: $primary-color;
    }
  }
}
.stak {
  @include stak;
  //  background-color: $white;
}
.blue-border {
  background-color: $secondary-color;
  padding: 1em 2em;
  .row {
    background-color: $white;
    padding: 1em 2em;
  }
}
@mixin colored-stak {
  position: relative;
  //padding-left: 2em;
  //padding-right: 2em;
  &:after {
    content: "";
    position: absolute;
    mix-blend-mode: multiply;
    opacity: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
  }
  &.photo-bgd-callout {
    .photo-bgd {
      &:after {
      }
    }
  }
  .content, .hero-caption {
    position: relative;
    z-index: 2;
  }
}
.alt-stak {
  @include colored-stak;
  &:after {
    background-color: $ghost;
  }
  &.pattern {
    &:after {
      mix-blend-mode: soft-light;
    }
  }
}
.red-stak:not(.slick-slide) {
  @include colored-stak;
  &:not(.photo-bgd-callout) {
    h2, h3, h4, p, ul {
      color: $white;
    }
  }
  &:after {
    background-color: $primary-color;
  }
  &.photo-bgd-callout {
    h3 {
      color: $secondary-color;
    }
    .photo-bgd {
      &:after {
        color: $primary-color;
      }
    }
  }
}
.blue-stak:not(.slick-slide) {
  @include colored-stak;
  &:not(.photo-bgd-callout) {
    h2, h3, h4, p, ul {
      color: $white;
    }
  }
  &:after {
    background-color: $secondary-color;
  }
  &.photo-bgd-callout {
    h3 {
      color: $navy-blue;
    }
    .photo-bgd {
      &:after {
        color: $secondary-color;
      }
    }
  }
}
.cyan-stak:not(.slick-slide) {
  @include colored-stak;
  &:not(.photo-bgd-callout) {
    h2, h3, h4, p, ul {
      color: $white;
    }
  }
  &:after {
    background-color: $light-blue;
    // background-blend-mode: luminosity;
    // mix-blend-mode: screen;
  }
  &.photo-bgd-callout {
    h3 {
      color: $navy-blue;
    }
    .photo-bgd {
      opacity: .25;
      &:after {
        background-color: $light-blue;
        mix-blend-mode: color;
      }
    }
  }
}
.black-stak:not(.slick-slide) {
  &:not(.photo-bgd-callout) {
    h2, h3, h4, p, ul {
      color: $white;
      small {
        color: $blue;
        font-size: 70%;
        text-transform: none;
        font-weight: 300;
      }
    }
  }
  background: $black !important;
}
.navy-stak:not(.slick-slide) {
  @include colored-stak;
  &:not(.photo-bgd-callout) {
    h2, h3, h4, p, ul {
      color: $white;
    }
  }
  .card {
    ul {
      color: $body-font-color;
    }
  }
  &:after {
    background-color: $navy-blue;
    // background-blend-mode: luminosity;
    // mix-blend-mode: screen;
  }
  &.photo-bgd-callout {
    h3 {
      color: $light-blue;
    }
    .photo-bgd {
      opacity: .25;
      &:after {
        background-color: $navy-blue;
        mix-blend-mode: color;
      }
    }
  }
  .hero {
    background-color: $white;
  }
}
.pattern {
  background-image: url("../images/bgd-angles-pattern.webp");
  z-index: 0;
}
.row .stak .row {
  padding-left: $global-padding;
  padding-right: $global-padding;
}
// HOMEPAGE
//////////////////////////////////////////////////////////////////////////
#home-top-bar {
  width: 100%;
}
.home-hero {
  margin: 0;
  backface-visibility: hidden;
  .hero-caption {
    position: absolute;
    z-index: 1;
    //top: 0;
    right: 0;
    left: 0;
    width: 90%;
    text-align: left;
    //padding: 100px 0 0;
    h1 {
      font-weight: 900;
      margin: 0;
      //text-transform: uppercase;
    }
    .subtitle {
      font-family: $body-font-family;
      font-weight: 300;
      margin: 0;
      font-size: 1em;
      // color: $white;
      line-height: normal;
    }
    .subtitle-image {
      max-width: 100px;
      margin-bottom: .5em;
    }
    h1,
    h3 {
      //color: $white;
      line-height: 1.05em;
    }
    p {
      // color: $white;
      font-size: $paragraph-font-size;
      margin-top: 1em;
      a {
        color: $primary-color;
      }
    }
    .button {
      @include button;
      margin-top: 1em;
    }
  }
  @include breakpoint(medium) {
    .hero-caption {
      p {
        font-size: $paragraph-font-size + 0.25;
      }
      .button {
        @include button;
        margin-top: 1em;
        font-weight: bold;
      }
    }
  }
  @include breakpoint(large) {
    .hero-caption {
      // padding: 100px 0 0;
      h1 {
        font-size: f-header-size(medium, h1);
      }
      .subtitle {
        font-size: f-header-size(small, h4);
      }
    }
  }
  @include breakpoint(xlarge) {
    .hero-caption {
      h1 {
        font-size: f-header-size(medium, h1) + .25;
      }
      .subtitle {
        font-size: f-header-size(small, h3);
      }
    }
  }
}
.carousel-hero {
  margin-top: 0;
  margin-left: 0;
  display: none;
  opacity: 0;
  transition: opacity 0.25s ease-in;
  &.slick-initialized {
    opacity: 1;
    display: block;
  }
  @include hero-slide;
  // @include breakpoint(large) {
  //    .slick-next,
  //    .slick-prev {
  //       top: 48%;
  //    }
  // }
  // .slick-slide class is jQuery generated
  .slick-slide {
    @include hero-slide;
    position: relative;
    // @include breakpoint(xlarge) {
    //    clip-path: polygon(0 0, 0 97%, 48% 97%, 50% 100%, 52% 97%, 100% 97%, 100% 0);
    // }
    &.alt-stak {
      &:before {
        @include overlay-white;
      }
    }
    &.cyan-stak {
      &:before {
        @include overlay-cyan;
      }
      h1, p {
        color: $white !important;
      }
    }
    &.red-stak {
      &:before {
        @include overlay-red;
      }
      h1, p {
        color: $white !important;
      }
    }
    &.blue-stak {
      &:before {
        @include overlay-blue;
      }
      h1, p {
        color: $white !important;
      }
    }
    &.navy-stak {
      &:before {
        @include overlay-navyblue;
      }
      h1, p {
        color: $white !important;
      }
    }
    &.pattern {
      &:before {
        @include overlay-pattern;
      }
    }
  }
  .slide-caption {
    @include grid-column(12);
    position: absolute;
    bottom: 30px;
    right: 0;
    left: 0;
    font-size: $paragraph-font-size - 0.25;
    text-align: left;
    p {
      background-color: rgba($white, 0.9);
      text-align: left;
      text-transform: uppercase;
      font-weight: 700;
      padding: 1em;
      display: inline;
      border: 1px solid rgba($white, 0.8);
    }
  }
  @include breakpoint(medium) {
    .slide-caption {
      font-size: $paragraph-font-size;
    }
  }
}
.hover-callouts {
  margin: 0 auto;
  > div {
    vertical-align: bottom;
    transition: all 0.5s;
    position: relative;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: cover !important;
    overflow: hidden;
    background-clip: content-box !important;
    min-height: 300px;
    display: table;
    border: 4px solid $white;
    &:first-of-type {
      border-bottom: 4px solid $white;
    }
    > div {
      border-collapse: collapse;
      display: table-row;
      background-color: rgba($dark-gray, .9);
    }
    .meta {
      height: 96%;
      text-align: center;
      border: 1px solid $transparent;
      padding: 0.5em;
      margin: 0.5em 0 0;
      display: table-cell;
      vertical-align: middle;
      h3,
      h4 {
        font-weight: 400;
        color: $white;
        margin-bottom: 0;
        line-height: 1.25em;
      }
      h3 {
        font-size: f-header-size(small, h3);
      }
      hr {
        margin: 0.5em 0;
        border-color: $white;
      }
      p {
        color: $white;
        margin: 1em 0;
      }
      a.button {
        color: $white;
        border-color: $white;
        transition: all .25 !important;
        transition-delay: 0s !important;
        &:hover {
          background-color: $white;
          color: $black;
        }
      }
    }
    @include breakpoint(medium) {
      overflow: hidden;
      background-size: cover !important;
      transition: all 0.25s;
      min-height: 400px;
      display: block;
      > div {
        background-color: rgba($dark-gray, 0.65);
        transition: all 0.5s;
        height: 100%;
        padding: 0.5em;
        display: block;
      }
      .meta {
        transform: translate(0px, 40%);
        transition: all 0.5s;
        display: block;
        h3 {
          color: $white;
        }
        h3 {
          font-size: f-header-size(medium, h3) -.5;
        }
        p {
          color: $white;
          opacity: 0;
          transition-delay: 0;
        }
        hr {
          opacity: 0;
          transition-delay: 0;
          width: 80%;
          margin-right: auto;
          margin-left: auto;
        }
        a {
          opacity: 0;
          transition-delay: 0;
        }
      }
      &:hover {
        > div {
          background-color: rgba($dark-gray, .9);
        }
        .meta {
          transform: translate(0px, 30%);
          transition-delay: 0.25s;
          p {
            opacity: 1;
            transition: all 1s;
            transition-delay: 0.25s;
          }
          hr {
            opacity: 1;
            transition-delay: 0.25s;
          }
          a {
            opacity: 1;
            transition: all 1s;
            transition-delay: 0.5s;
          }
        }
      }
    }
    &:hover {
      background-size: 120%;
    }
    @include breakpoint(xxlarge) {
      .meta {
        h3 {
          font-size: f-header-size(medium, h3);
        }
      }
    }
  }
}
.stats {
  margin-top: 2em;
  font-family: $header-font-family;
  font-weight: 300;
  line-height: 1.25em;
  text-align: center;
  .column {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .stat {
    text-align: center;
    strong {
      font-weight: 600;
      display: block;
      font-size: 1.5em;
      line-height: 1.5em;
      color: $primary-color;
      vertical-align: top;
      white-space: nowrap;
      .stat-append {
        font-size: .75em;
        vertical-align: top;
        display: inline-block;
        margin-top: -.45em;
        margin-left: -.25em;
        color: $secondary-color;
      }
    }
    @include breakpoint(medium) {
      strong {
        font-size: 2em;
      }
    }
    @include breakpoint(large) {
      strong {
        font-size: 2.5em;
      }
    }
    @include breakpoint(xlarge) {
      strong {
        font-size: 3em;
      }
    }
  }
}
.testimonials {
  text-align: center;
  background: linear-gradient(180deg, rgba($gray, 0) 98%, rgba($gray, 0.65) 100%);
  //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
  // box-shadow:  0 0 3px 2px rgba($white,0.9);
  box-shadow: 0px 50px 50px 50px rgba(255, 255, 255, 1) inset;
}
.quote-slider {
  .slick-track {
    align-items: center;
    justify-content: center;
    display: flex !important;
    .testimonial {
      padding: 1em;
      margin: 1em;
      background-color: $primary-color;
      color: $white;
      &:nth-child(odd) {
        background-color: $secondary-color;
      }
      &:after {
        content: "";
        position: absolute;
        mix-blend-mode: multiply;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background-image: url("../images/bgd-angles-pattern.webp");
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Flaticon, serif;
        font-size: 100px;
        color: $white;
        margin: 0 auto;
        // mix-blend-mode: normal;
        opacity: .8;
      }
    }
    .slick-slide {
      display: flex;
      align-items: center; //optional
      justify-content: center; //optional
      flex-direction: column;
    }
  }
  blockquote {
    margin: 0;
    border-left: none;
    display: relative;
    quotes: "“" "”" "‘" "’";
    color: $white;
    font-weight: bold;
    &::before {
      content: open-quote;
      font-size: 150%;
      margin-bottom: -.5em;
      display: inline-block;
      font-weight: bold;
    }
    &::after {
      content: close-quote;
      font-size: 150%;
      margin-top: -.5em;
      display: inline-block;
      font-weight: bold;
    }
    p {
      margin: 0;
      color: $body-font-color;
      //&:before {
      //  font-size: 200%;
      //  content: '“';
      //  position: absolute;
      //  left: -1px;
      //  top: -10px;
      //}
      //&:after {
      //  font-size: 200%;
      //  content: '”';
      //  position: absolute;
      //  right: -1px;
      //  bottom: -10px;
      //}
    }
    @include breakpoint(large) {
      p {
        font-size: 110%;
      }
    }
  }
  footer {
    margin: 10px 0;
  }
}
.showroom-callout {
  position: relative;
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba($white, .5);
    top: 0;
  }
}
.client-logos {
  margin: 20px 0 0;
  .ri-grid {
    margin: 0 auto;
    position: relative;
    height: auto;
  }
  .ri-grid ul {
    list-style: none;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  /* Clear floats by Nicolas Gallagher: http://nicolasgallagher.com/micro-clearfix-hack/ */
  .ri-grid ul:after,
  .ri-grid ul:before {
    content: '';
    display: table;
  }
  .ri-grid ul:after {
    clear: both;
  }
  .ri-grid ul {
    zoom: 1;
    /* For IE 6/7 (trigger hasLayout) */
  }
  .ri-grid ul li {
    perspective: 250px;
    //height: 200px!important;
    margin: 0;
    padding: 0;
    float: left;
    position: relative;
    display: block;
    overflow: hidden;
    //border: 1em solid $white;
    @include breakpoint(medium) {
      //border: 1em solid transparent;
    }
  }
  .ri-grid ul li a,
  .ri-grid ul li div {
    display: block;
    outline: none;
    position: absolute;
    z-index: 1002;
    margin: 0 auto;
    padding: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    background-size: 90%;
    background-position: center center;
    background-repeat: no-repeat;
    box-sizing: content-box;
    filter: grayscale(100%) contrast(110%);
    transition: all 0.25s ease-in-out;
    &:hover {
      filter: grayscale(0%) contrast(100%);
    }
  }
  /* Grid wrapper sizes */
  .ri-grid-size-1 {
    width: 55%;
  }
  .ri-grid-size-2 {
    width: 100%;
  }
  .ri-grid-size-3 {
    width: 100%;
    //margin-top: 0;
  }
  .ri-grid-loading:after,
  .ri-grid-loading:before {
    display: none;
  }
  .ri-loading-image {
    display: none;
  }
  .ri-grid-loading .ri-loading-image {
    position: relative;
    width: 30px;
    height: 30px;
    left: 50%;
    margin: 100px 0 0 -15px;
    display: block;
  }
}
// ABOUT
//////////////////////////////////////////////////////////////////////////
.landing {
  h2 {
    display: initial;
  }
  .hero {
    min-height: 300px;
    background-size: 100%;
    background-position: center center;
    //margin-bottom: 2.500em;
    position: relative;
    background-color: $light-gray;
    .hero-caption {
      color: white;
      text-align: center;
      transform: translate(0%, -50%);
      top: 50%;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      h1,
      h2 {
        color: white;
      }
      h2 {
        font-weight: 300;
        font-size: f-header-size(medium, h2) - 1;
      }
      h1 {
        font-size: f-header-size(medium, h1) - 0.5;
        text-transform: uppercase;
        font-weight: 700;
      }
      p {
        font-size: $paragraph-font-size;
        a {
          color: white;
          font-weight: 700;
        }
      }
    }
    @include breakpoint(medium) {
      min-height: 350px;
      background-size: cover !important;
      background-position: center bottom;
    }
    @include breakpoint(large) {
      min-height: 400px;
      background-size: cover !important;
      .hero-caption {
        h1 {
          font-size: f-header-size(medium, h1) + 0.5;
        }
        h2 {
          @include header-size(h2);
        }
      }
    }
    @include breakpoint(xlarge) {
      min-height: 550px;
      .hero-caption {
        padding-top: 4em;
        // h1 {
        //    font-size: f-header-size(medium, h1) + 1.25;
        // }
        // h2 {
        //    font-size: f-header-size(medium, h2) + 1;
        // }
      }
    }
  }
  //.stak:nth-child(even) {
  //  background-color: $light-gray;
  //}
}
.video-hero {
  position: relative;
  overflow: hidden;
  &:after {
    mix-blend-mode: normal;
    opacity: .8;
    z-index: 2 !important;
  }
  #bg-video {
    // background: url('poster.jpg') no-repeat;
    background-size: cover;
    position: absolute;
    width: 140%;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    @include breakpoint(medium) {
      width: 100%;
      height: auto;
    }
  }
  .video-foreground {
    z-index: 3;
  }
}
// FLEXIBLE CONTENT BLOCKS
.photo-bgd-callout {
  z-index: 0;
  position: relative;
  .photo-bgd {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 30%;
    background-position: center center !important;
    background-size: cover;
    z-index: 0;
    &:after {
      content: "";
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 70%;
      z-index: 0;
    }
  }
  .content {
    margin: 2em auto;
    padding: 1em;
    background-color: rgba($white, 1);
    text-align: center;
    @include box-shadow;
    @include radius;
    img {
      border-radius: 100%;
      border: 3px solid $secondary-color;
      margin-top: -20px;
      max-width: 120px;
    }
  }
  @include breakpoint(large) {
    .content {
      img {
        max-width: initial;
      }
    }
    .photo-bgd {
      background-attachment: fixed !important;
    }
  }
  &.personal-callout {
    @include breakpoint(medium) {
      h3 {
        font-size: f-header-size(small, h3) !important;
      }
    }
  }
  .personal-callout-person {
    p {
      margin: .5em auto;
      line-height: 1em;
    }
    span {
      display: block;
      font-size: .7em;
    }
    a {
      color: $black;
      span {
        color: $medium-gray;
      }
      &:hover {
        color: $secondary-color;
      }
    }
  }
}
.cards-stak {
  .card-description {
    margin-bottom: 1em;
    li {
      line-height: 1.25em;
      margin-bottom: .5em;
    }
  }
  &.icons {
    img {
      height: 40px;
      max-width: 60px;
      margin: 1em 0 0 .5em;
    }
  }
  &.collapse {
    .card {
      margin: 0;
    }
  }
  &.blue-stak {
    .card {
      p, h4, li {
        color: $body-font-color;
      }
    }
  }
  .card.normal-style {
    margin: 1em 0;
    //border: none;
  }
  .card.feature-style {
    border-radius: 1em;
    h4 {
      background-color: $navy-blue;
      font-weight: bold;
      text-align: center;
      border-radius: .25em;
      color: $secondary-color;
      padding: .5em;
      margin-bottom: 1em;
    }
    h5 {
      font-weight: bold;
      text-align: center;
      color: $navy-blue;
    }
    ul {
      margin: 1em 0 0 .5em;
      list-style: none;
      li {
        margin: 0 0 1em 1em;
        text-indent: -1.5em;
        line-height: 1.25em;
        // text-indent: -45px;
        // padding-left: 50px;
        &:before {
          content: "\e051";
          margin: 0 4px 0 0;
          font-family: 'flaticon';
          color: $primary-color;
          font-size: 1rem;
        }
      }
    }
  }
  .card {
    .card-divider {
      background-color: $transparent;
    }
    &.white-card {
    }
    &.cyan-card {
      .card-divider {
        background-color: $cyan;
        h4 {
          color: darken($cyan, 40%);
        }
      }
      .card-section {
        background-color: lighten($cyan, 30%);
        li {
          &:before {
            color: $cyan;
          }
        }
      }
    }
    &.red-card {
      .card-divider {
        background-color: $red;
        h4 {
          color: lighten($red, 80%);
        }
      }
      .card-section {
        background-color: lighten($red, 40%);
        li {
          &:before {
            color: $red;
          }
        }
      }
    }
    &.pink-card {
      .card-divider {
        background-color: $pink;
        h4 {
          color: lighten($pink, 80%);
        }
      }
      .card-section {
        background-color: lighten($pink, 30%);
        li {
          &:before {
            color: $pink;
          }
        }
      }
    }
    &.blue-card {
      .card-divider {
        background-color: $blue;
        h4 {
          color: lighten($blue, 50%);
        }
      }
      .card-section {
        background-color: lighten($blue, 40%);
        li {
          &:before {
            color: $blue;
          }
        }
      }
    }
    &.navy-card {
      .card-divider {
        background-color: $navy-blue;
        h4 {
          color: lighten($navy-blue, 50%);
        }
      }
      .card-section {
        background-color: lighten($navy-blue, 40%);
        li {
          &:before {
            color: $navy-blue;
          }
        }
      }
    }
  }
}
.advantage {
  .card {
    h4 {
      font-weight: 400;
    }
    background: none;
    border: none;
    border-left: 3px solid $primary-color !important;
    .card-divider {
      background-color: $transparent;
      padding-top: 0;
    }
    .card-section {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
.cx-cards {
  h3 {
    margin: 0 auto .75em auto;
    color: $primary-color !important;
  }
  h4 {
    font-weight: 500;
    color: $secondary-color !important;
  }
  ul {
    margin: 0;
    list-style: none;
    li {
      margin: 0 0 .5em 0;
      text-indent: -38px;
      padding-left: 30px;
      font-weight: 400;
      font-size: .85em;
      color: $navy-blue;
      &:before {
        content: "\e0da";
        margin: -9px 4px 0 0;
        font-family: 'flaticon';
        color: $primary-color;
        font-size: 20px;
      }
    }
  }
}
.article-excerpt {
  margin-bottom: 1em;
}
// VIDEO
.flex-video {
  iframe {
    border: 0;
  }
}
.video-background {
  background-color: $light-gray;
  padding: 15px;
  margin: 0 15px !important;
}
.video-player {
  // background-color: #fff;
  padding-bottom: 5px;
  .poster {
    position: relative;
    img {
      width: 100%;
    }
  }
  .play-button {
    position: absolute;
    width: 80px;
    height: 80px;
    margin: auto;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    transition: opacity 0.5s ease;
    &:hover {
      opacity: 0.5;
    }
  }
}
.split-image {
  img:not(.play-icon) {
    margin-bottom: 2em;
    @include box-shadow;
    @include radius;
    border: 8px $white solid;
    border-radius: 1em;
    @include breakpoint(medium) {
      margin-bottom: 0;
    }
  }
}
h3,
h4 {
  &.underline {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 2px;
      background-color: $secondary-color;
    }
  }
  line-height: 1.15em;
}
.align-middle,
.collapse,
.fill-image {
  .content {
    padding: 2em;
  }
}
.fill-image {
  .columns {
    min-height: 200px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    @include breakpoint(medium) {
      background-size: auto 100%;
    }
  }
}
.alternating-staks {
  .stak {
    &:nth-child(odd) {
      background-color: $light-gray;
    }
  }
  img.thumbnail {
    margin: 0.5em auto;
    width: 90%;
  }
}
.gallery-photo {
  &.slick-slider {
    margin-bottom: 4px !important;
  }
  > div {
    // max-width: 320px;
    overflow: hidden;
    vertical-align: middle;
    margin-bottom: 0;
    img {
      //max-width: 640px;
      width: 100%;
      vertical-align: middle;
      margin: 0 auto;
      transform: scale(1);
      transition: transform 0.25s;
    }
  }
}
.gallery-thumb {
  //max-width: 640px;
  max-height: 98px;
  background-color: $white;
  div.slick-slide {
    //min-width: 120px;
    max-height: 90px !important;
    overflow: hidden;
    vertical-align: middle;
    margin: 0;
    text-align: center;
    margin: 0 2px;
    .slick-list {
      max-height: 90px;
    }
    img {
      max-width: 120px;
      min-height: 90px;
      vertical-align: middle;
      padding: 0 4px 0 0;
      margin: 0 auto;
    }
    @include breakpoint(large) {
      img {
        max-width: 150px;
        min-height: 120px;
      }
    }
  }
  .slick-next,
  .slick-prev {
    top: 0;
    background-color: rgba($white, .9);
    z-index: 1;
    height: 100%;
    &:before {
      color: $black;
    }
  }
}
.photo-gallery {
  justify-content: center;
  text-align: center;
}
.tab-columns-stak {
  background-color: lighten($light-gray, 10%);
  .tabs {
    background-color: transparent;
    border: none;
    .tabs-title {
      background-color: transparent;
      padding: 0;
      margin-bottom: .25em;
      border-left: 2px solid transparent;
      &.is-active {
        border-left: 2px solid $secondary-color;
        background-color: transparent;
      }
      &:hover {
        border-left: 2px solid $light-blue;
        background-color: transparent;
      }
      > a {
        color: $dark-gray;
        font-size: 1em;
        font-weight: 600;
        margin-right: .5rem;
        border-top: 1px solid $light-gray;
        &[aria-selected='true'], &:focus, &:hover {
          background-color: transparent;
        }
      }
    }
    // > .tabs-title:last-of-type {
    //    > a {
    //       border-bottom: 1px solid $light-gray;
    //    }
    // }
  }
  .tabs-content {
    border: none;
    border-top: 0;
    background: transparent;
    color: $body-font-color;
    transition: all 0.5s ease;
  }
  .tabs-panel {
    padding: 0;
    p {
      line-height: 1.75em;
    }
  }
}
.tabs-stak {
  .tabs {
    background-color: transparent;
    border: none;
    display: block;
    border-bottom: 2px solid $light-gray;
    li {
      margin-bottom: -2px;
    }
    .tabs-title {
      background-color: transparent;
      transition: all .25s;
      float: none;
      &.is-active {
        border-bottom: 2px solid $white;
        background: linear-gradient(180deg, rgba($ghost, 0) 0%, $white 100%);
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
        box-shadow: 0 -3px 5px 0 rgba(0, 0, 0, 0.25);
        > a {
          color: $secondary-color;
        }
      }
      &:hover {
        border-bottom: 2px solid $secondary-color;
        background-color: $snow;
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
        box-shadow: 0 -3px 5px 0 rgba(0, 0, 0, 0.25);
        > a {
          color: $primary-color;
        }
      }
      > a {
        transition: all .25s;
        cursor: pointer;
        font-size: 1em;
        font-weight: 600;
        &[aria-selected='true'], &:focus, &:hover {
          background-color: transparent;
        }
      }
    }
    @include breakpoint(medium) {
      display: flex;
      .tabs-title {
        float: left;
      }
    }
    &.vertical {
      flex-direction: column;
      border-bottom: none;
      &.align-right {
        text-align: right;
      }
      .tabs-title {
        border-bottom: none;
        border-right: 2px solid $light-gray;
        border-top-left-radius: .5em;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: .5em;
        &.is-active {
          border-bottom: none;
          border-right: 2px solid $secondary-color;
          box-shadow: none;
          > a {
            color: $secondary-color;
          }
        }
        &:hover {
          box-shadow: none;
          border-bottom: none;
          border-right: 2px solid $secondary-color;
        }
      }
    }
  }
  .tabs-content {
    border: none;
    background-color: $transparent;
    .tabs-panel.is-active {
      display: block;
      animation: fadein-animation .7s ease-in-out;
      opacity: 1;
    }
    @include breakpoint(large) {
      .tabs-panel {
        padding: 2em;
        .center {
        }
        p {
          margin-bottom: .5em;
          margin-top: .5em;
        }
      }
      .tabs-panel.is-active {
        display: flex;
        align-items: center;
      }
    }
  }
}
.team-member-card {
  a {
    color: $black;
    transition: color .25s;
    &:hover {
      color: $button-background-hover;
      img {
        transform: scale(1.025);
      }
    }
  }
  img {
    margin-bottom: .75em;
    transition: all .25s;
  }
  text-align: center;
  h4 {
    margin: 0 auto;
  }
  .person-title {
    color: $dark-gray;
  }
  .lifestyle-image {
    margin: 0 auto;
    max-width: 460px;
  }
  @include breakpoint(medium) {
    margin-bottom: 2em;
    h4 {
      font-size: f-header-size(small, h4)*1.15;
    }
  }
}
.bios {
  h2 {
    display: inline-block;
    font-weight: 300;
  }
  a.bio-linkedin {
    display: inline-block;
    color: $black;
    font-size: 2em;
    transition: color .25s;
    &:hover {
      color: $button-background-hover;
    }
  }
  .lifestyle-image {
    display: block;
    margin: 0 auto 2em auto;
    max-width: 460px;
  }
  blockquote {
    border-left: 2px solid $secondary-color;
    color: $light-gray;
    @include breakpoint(large) {
      margin: 0 0 1rem 0;
      padding: 0.5625rem 1.25rem 0 1.25rem;
      font-size: 1.25em;
    }
  }
}
// BLOG
/////////////////////////////////
.work {
  h2 {
    small {
      color: $secondary-color;
    }
  }
  .section-title {
    h3 {
      font-weight: 300;
      margin-top: 0;
      font-size: f-header-size(medium, h3) - 1;
    }
    h2 {
      font-weight: 300;
      font-size: f-header-size(medium, h2) - 1;
      margin-top: 0;
    }
  }
}
aside.sticky-container {
  z-index: 1 !important;
  @include breakpoint(large) {
    .sticky.is-at-top.is-stuck {
      padding-top: 3em;
    }
  }
}
// Article
.more-articles {
  padding-top: 2em;
  .button {
    border-radius: $button-radius;
    span {
      display: none;
    }
  }
}
.off-canvas.position-left.is-open {
  background: $silver;
  z-index: 1000;
  aside {
    padding: 0 .5em;
    background: $silver;
  }
}
@mixin colored-card {
  position: relative;
  transition: all .25s;
  //height: 100%;
  width: 100%;
  display: block;
  &:after {
    content: "";
    position: absolute;
    mix-blend-mode: multiply;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-image: url("../images/bgd-angles-pattern.webp");
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Flaticon, serif;
    font-size: 100px;
    color: $white;
    margin: 0 auto;
    // mix-blend-mode: normal;
    opacity: .8;
  }
  &:before {
    content: "";
    position: absolute;
    mix-blend-mode: multiply;
    opacity: .25;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    height: 100%;
    width: 100%;
    background-color: rgba($secondary-color, .15);
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    transition: all .25s;
  }
  &:hover {
    &:before {
      opacity: 1;
    }
  }
}
.article-cards, .tease-post {
  .card {
    transition: all 0.5s;
    display: block;
    background-color: transparent;
    overflow: hidden;
    border: none;
    //margin: .9em;
    box-shadow: 0 10px 20px -20px rgba(0, 0, 0, 0.5);
    figure {
      @include colored-card;
      border-top-left-radius: 1em;
      border-top-right-radius: 1em;
      border: 8px $white solid;
      a {
      }
      img {
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
      }
    }
    &.video {
      figure {
        &:after {
          content: "\e0eb";
          z-index: 1;
          background-image: none;
          mix-blend-mode: normal;
          opacity: .8;
        }
        &:before {
          background-color: rgba($black, .25);
          mix-blend-mode: darken;
        }
      }
    }
    &.link {
      figure {
        &:after {
          content: "\e062";
          z-index: 1;
          background-image: none;
          mix-blend-mode: normal;
          opacity: .8;
        }
        &:before {
          background-color: rgba($blue, .5);
          mix-blend-mode: multiply;
        }
      }
    }
  }
  p {
    font-size: .8em;
    margin: .5em auto;
  }
  .date {
    color: $gray;
    font-size: .8em;
  }
  h4, h5 {
    color: $black;
    font-weight: bold;
    font-size: f-header-size(medium, h5);
  }
  a {
    h4, h5 {
      color: $black;
      font-weight: bold;
    }
    &:hover {
      h4, h5 {
        color: $secondary-color;
      }
    }
  }
  .article-thumb {
    width: 100%;
    display: block;
    position: relative;
  }
  .article-categories {
    margin-bottom: 1em;
    a {
      text-transform: uppercase;
      font-size: .8em;
      color: $secondary-color;
      font-weight: bold;
      &:hover {
        color: lighten($primary-color, 8%);
      }
      &:before {
        content: ", ";
      }
    }
    a:first-of-type {
      &:before {
        content: "";
      }
    }
  }
  &:hover {
    img {
      width: 110%;
    }
  }
  .card-section {
    background-color: $white;
  }
}
@include breakpoint(medium) {
  .card {
    transition: all 0.25s;
    display: block;
    margin: .9em;
    .card-section {
      transition: all 0.5s;
      padding: 1.5em 1em;
      p {
        font-size: 1em;
      }
    }
  }
}
.breadcrumbs {
  margin-top: 2em;
  vertical-align: middle;
  font-size: 0.85em;
  color: $medium-gray;;
  a {
    color: $secondary-color;
    &:hover {
      text-decoration: underline;
    }
  }
  span {
    //font-size: 1em;
  }
  .breadcrumb_last {
    color: $medium-gray;
    cursor: not-allowed;
  }
}
.pagination-block {
  text-align: center;
  border-top: 1px solid $light-gray;
  padding-top: 2em;
  .disabled {
    color: lighten($gray, 20%);
  }
  .prev {
    float: left;
  }
  .next {
    float: right;
  }
  .current {
    font-weight: bold;
  }
}
.article {
  h1 {
    font-weight: 300;
    color: $secondary-color;
    small {
      font-weight: lighter;
      letter-spacing: .1em;
      font-size: .3em;
      &.under-subtitle {
        color: $primary-color;
        margin: 1em 0 2em 0;
      }
    }
  }
  h2, h3 {
    color: $dark-gray;
    font-weight: normal;
    b {
      font-weight: normal;
    }
  }
  p {
    a {
      color: lighten($primary-color, 20%);
      &:hover {
        color: $primary-color;
        text-decoration: underline;
      }
    }
  }
  .lead p {
    font-weight: normal;
  }
  .division-logo {
    max-width: 100px;
    float: right;
    margin-top: -4em;
  }
  .page-content {
    margin-top: 4em;
  }
  .cat-links {
    margin-top: 2em;
    display: block;
    a {
      padding: 0 .25em;
      &:after {
        content: ","
      }
      &:last-of-type {
        &:after {
          content: "";
        }
      }
    }
  }
  .featured-image {
    margin-bottom: 2em;
  }
  .article-pagenation {
    margin: 2em auto;
    padding: 2em;
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;
    a {
      h4 {
        color: $dark-gray;
        //text-decoration: underline;
        font-size: .9em;
        margin-bottom: 0;
      }
      span {
        font-size: .8em;
        line-height: 1em;
        display: block;
        color: lighten($primary-color, 20%);
      }
      &:hover {
        h4, span {
          color: $secondary-color !important;
        }
      }
    }
    @include breakpoint(medium) {
      justify-content: space-between;
      .next-post {
        text-align: right;
      }
    }
  }
  @include breakpoint(medium) {
    h1 {
      font-size: f-header-size(medium, h1) + 1;
      line-height: 1.15em;
    }
    .division-logo {
      max-width: 150px;
      margin-top: -6em;
    }
  }
  @include breakpoint(large) {
    h1 {
      font-size: f-header-size(medium, h1) + 2;
      line-height: 1.15em;
    }
    .division-logo {
      max-width: 200px;
      margin-top: -6em;
    }
  }
  @include breakpoint(xlarge) {
    // enhancements;
    .stak.lead {
      padding: 40px 0;
    }
  }
}
.recent-articles {
  margin-top: 1em;
}
.recent-articles-list {
  margin-bottom: 1em;
  img {
    margin-right: 1em;
  }
  .article-date {
    font-size: .8em;
    margin-bottom: 0;
  }
  .article-title {
    h6 {
      margin-top: 0;
    }
    a:hover h6 {
      //   color: lighten($primary-color, 8%);
    }
  }
}
.article-category-list {
  &.accordion-menu .nested.is-accordion-submenu {
    margin-left: 0;
  }
  .is-accordion-submenu {
    margin-left: 0 !important;
  }
  a {
    padding: 0.7rem 1rem .7rem 0;
    display: inline;
    margin: 0;
    font-size: 0.875rem;
    line-height: 1.8;
    color: #101921;
    &:hover {
      color: $secondary-color;
    }
    position: relative;
  }
  .article-count {
    display: inline-block;
    width: 1.5em;
    font-size: .5em;
    color: $gray;
    //margin-right:  1rem;
    position: absolute;
    right: 0;
  }
  .category-list {
    margin-bottom: 1em;
    font-weight: bold;
    .parent-category {
      font-weight: bold;
    }
    .children {
      font-weight: normal;
      label {
        font-size: .8rem;
      }
    }
    li {
      margin-bottom: 0;
      li {
        border-bottom: $light-gray 1px solid;
        margin-bottom: 0.25em;
        padding-bottom: 0.25em;
      }
    }
    input[type=checkbox]:hover + label {
      text-decoration: underline;
    }
    input[type=checkbox]:checked + label {
      color: $secondary-color;
    }
    [type='checkbox'] + label, [type='radio'] + label {
      margin-right: 0;
    }
  }
}
// Careers
.job-openings {
  margin-bottom: 2em;
}
// .comment-box {
//    margin-top: 2em;
//    padding-top: 2em;
//    border-top: 4px solid $light-gray;
// }
// .comment-form {
//    input, textarea {
//       border: none;
//       background-color: $light-gray;
//       box-shadow: none;
//       color: $gray;
//       &::placeholder {
//          color: lighten($gray, 20%);
//       }
//       &:focus::placeholder{
//          color:transparent;
//       }
//    }
//    input {
//       margin-bottom: 1em;
//    }
//    label {
//       display: none;
//    }
//    textarea {
//       min-height: 148px;
//    }
//    sup {
//       display: block;
//       font-family: $font-family-serif;
//       margin: 1em auto;
//       font-style: italic;
//       color: $gray;
//    }
//    h4 {
//       display: block;
//       font-style: normal;
//       text-transform: uppercase;
//       font-size: f-header-size(small, h6)*.8;
//       color: $primary-color;
//       font-weight: bold;
//       margin-bottom: 1em;
//    }
// }
// SUBPAGES  - LANDING
//////////////////////////////////////////////////////////////////////////
.section-nav {
  margin: 2em auto 0 auto;
  display: inline-block;
  width: auto;
  height: 47.75px;
  ul > li {
    border-bottom: 2px solid $secondary-color;
    margin: 0;
  }
  li {
    a {
      display: block;
      color: $body-font-color;
      text-decoration: none;
      font-size: 1em;
      &:hover {
        color: $primary-color;
      }
      &.active {
        color: $primary-color;
      }
    }

      &.is-active {
        > a {
          background-color: transparent;
        }

    }
    &.section-nav-title {
      // text-transform: uppercase;
      width: 320px;
      position: relative;
      padding: .5em 0;
      font-size: 1.1em;
      font-weight: bold;
      &:after {
        font-family: Flaticon, serif;
        content: "\e007";
        position: absolute;
        right: 0;
        transition: all .25s;
        font-weight: bold;
        color: $primary-color;
        font-size: 1.25em;
      }
      &.is-active {
        &:after {
          //content: "\e009";
          transform: rotate(90deg);
        }
      }
    }
    .js-dropdown-active {
      //border-top-color: $white;
      border: none;
      filter: drop-shadow(0 5px 5px #d9dae4);
    }
    ul {
      padding-bottom: 1em;
      padding-inline-start: 0px;
      margin: 0;
      font-weight: normal;
      li {
        margin: 0;
        padding: 0;
        border: none;
        a {
          padding: 0 0 0 1.25em;
          margin: 0;
          font-size: 0.90em;
          color: $secondary-color;
        }
      }
    }
  }
}
//header {
//  background-size: 120%;
//  background-position: top center;
//  position: relative;
//  &:after {
//    z-index: 0;
//  }
//  &.pattern {
//    &:after {
//      content: "";
//      background-image: url("../images/bgd-angles-pattern.png");
//        position: absolute;
//        mix-blend-mode: multiply;
//        opacity: 1;
//        top: 0;
//        bottom: 0;
//        left: 0;
//        right: 0;
//        z-index: -1;
//        height: 100%;
//        width: 100%;
//    }
//  }
//}
.subpage {
  h2 {
    display: initial;
  }
  header {
    background-size: 120%;
    background-position: top center;
    position: relative;
    &:after {
      z-index: 0 !important;
    }
    &.pattern {
      &:before {
        content: "";
        background-image: url("../images/bgd-angles-pattern.webp");
        position: absolute;
        mix-blend-mode: multiply;
        opacity: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        height: 100%;
        width: 100%;
      }
    }
    &.video-hero.pattern {
      &:before {
        z-index: 4 !important;
      }
    }
    &.video-hero {
      position: relative;
      overflow: hidden;
      &:after {
        mix-blend-mode: normal;
        opacity: .8;
        z-index: 2 !important;
      }
    }
  }
  .hero {
    min-height: 260px;
    background-size: 120%;
    background-position: top center;
    //margin-bottom: 2.500em;
    position: relative;
    background-color: $light-gray;
    .hero-caption {
      color: white;
      text-align: left;
      //z-index: 1;
      //transform: translate(0%, -50%);
      //top: 50%;
      //position: absolute;
      //left: 0;
      //right: 0;
      //margin: auto;
      small {
        color: $white;
      }
      h1,
      h2 {
        color: white;
        line-height: 1em;
      }
      h1 {
        font-size: f-header-size(small, h1) - 0.5;
        font-weight: 700;
      }
      h2 {
        font-weight: 300;
        font-size: f-header-size(small, h2) - 0.5;
      }
      p {
        font-size: $paragraph-font-size;
        a {
          color: white;
          font-weight: 700;
        }
      }
    }
    @include breakpoint(medium) {
      min-height: 300px;
      background-size: cover !important;
    }
    @include breakpoint(large) {
      .hero-caption {
        h1 {
          font-size: f-header-size(medium, h1) + 0.5;
        }
        h2 {
          font-weight: 300;
          font-size: f-header-size(medium, h2) - 1;
        }
      }
    }
  }
}
.bespoke {
  min-height: 300px;
  background-size: cover !important;
  h2 {
    display: initial;
  }
  header {
    background-size: 120%;
    background-position: top center;
    &:after {
      z-index: 0;
    }
    &.pattern {
      &:before {
        content: "";
        background-image: url("../images/bgd-angles-pattern.webp");
        position: absolute;
        mix-blend-mode: multiply;
        opacity: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        height: 100%;
        width: 100%;
      }
    }
    &.video-hero.pattern {
      &:before {
        z-index: 4 !important;
      }
    }
  }
  .hero {
    min-height: 300px;
    background-size: 120%;
    background-position: top center;
    //margin-bottom: 2.500em;
    position: relative;
    background-color: transparent;
    .hero-caption {
      color: white;
      text-align: left;
      //transform: translate(0%, -50%);
      //top: 50%;
      //position: absolute;
      //left: 0;
      //right: 0;
      //margin: auto;
      position: relative;
      z-index: 2;
      small {
        color: $white;
      }
      h1,
      h2 {
        color: white;
        line-height: 1.15em;
      }
      h1 {
        font-size: f-header-size(small, h1) - 0.5;
        font-weight: 700;
      }
      h2 {
        font-weight: 300;
        font-size: f-header-size(small, h2) - 0.5;
      }
      p {
        font-size: $paragraph-font-size;
        a {
          color: white;
          font-weight: 700;
        }
      }
    }
    @include breakpoint(medium) {
      min-height: 300px;
      background-size: cover !important;
    }
    @include breakpoint(large) {
      background-size: cover !important;
      .hero-caption {
        h1 {
          font-size: f-header-size(medium, h1) + 0.5;
        }
        h2 {
          font-weight: 300;
          font-size: f-header-size(medium, h2) - 1;
        }
      }
    }
  }
  &.cmindex {
    .hero {
      .hero-caption {
        text-align: left;
        color: $white;
        h1,
        h2 {
          color: $primary-color;
        }
        small {
          color: $secondary-color;
        }
        p {
          color: $dark-gray;
        }
      }
    }
  }
}
.default {
  h2 {
    display: initial;
  }
  .hero {
    min-height: 100px;
    background-size: 120%;
    background-position: center center;
    //margin-bottom: 2.500em;
    position: relative;
    overflow: visible;
    .hero-caption {
      //transform: translate(0%, -50%);
      //top: 50%;
      //position: absolute;
      //left: 0;
      //right: 0;
      margin: 2em auto;
      h2 {
        font-weight: 300;
        font-size: f-header-size(medium, h2) - 1;
        margin-top: 0;
      }
      h1 {
        font-size: f-header-size(medium, h1) - 0.5;
        font-weight: 700;
        margin-bottom: 0;
      }
      p {
        font-size: $paragraph-font-size;
        a {
          color: white;
          font-weight: 700;
        }
      }
    }
    @include breakpoint(medium) {
      min-height: 200px;
      background-size: cover !important;
      background-position: center bottom;
    }
    @include breakpoint(large) {;
      background-size: cover !important;
      .hero-caption {
        margin: 2em auto 0 auto;
        h1 {
          font-size: f-header-size(medium, h1);
        }
        h2 {
          font-size: f-header-size(medium, h2) - 1;
        }
      }
    }
  }
}
.banner-title-image {
  max-height: 40px;
  text-align: left;
  @include breakpoint(medium) {
    max-height: 80px;
  }
}
img.shadow {
  @include box-shadow;
  @include radius;
  border: 8px $white solid;
  border-radius: 1em;
}
.post-type-video {
  .vimeo {
    @include box-shadow;
  }
}
.checkbox-container.label-right .field-wrap {
  display: block !important;
}
.ytp-pause-overlay {
  bottom: -200px !important; //give !important if not working
}
//CAPABILITIES
ul.checklist {
  margin: 1em 0;
  list-style: none;
  li {
    margin: 0 0 1em 0;
    text-indent: -45px;
    padding-left: 50px;
    &:before {
      content: "\e051";
      margin: -10px 10px 0 0;
      font-family: 'flaticon';
      color: $primary-color;
      font-size: 24px;
    }
  }
}
ul.red-bar {
  @include no-bullet;
  li {
    margin: 0;
    padding: .5em 0 .5em 1em;
    border-left: 3px solid $primary-color;
    font-weight: 400;
    color: $dark-gray;
  }
  ul {
    font-size: 1em !important;
    > li {
      border: none;
      padding: .25em 0 .25em 1em;
      font-weight: 300;
    }
  }
}
// EXPERTISE
.expertise {
  .stak {
    background: linear-gradient(180deg, rgba($ghost, 0) 0%, rgba($ghost, 1) 98%, rgba($gray, 0.45) 100%);
  }
}
.methodologies {
  ul:first-child {
    font-size: 1.15em !important;
    li {
      margin: 0;
      padding: .5em 0 .5em 1em;
      border-left: 3px solid $primary-color;
      font-weight: 400;
      color: $dark-gray;
    }
    ul {
      font-size: 1em !important;
      > li {
        border: none;
        padding: .25em 0 .25em 1em;
        font-weight: 300;
      }
    }
  }
}
.search-results {
  .page-content {
    margin-top: 2em;
  }
  .search-result {
    margin-bottom: .5em;
    padding-top: .5em;
    border-bottom: 1px solid $light-gray;
    &:last-of-type {
      border-bottom: none;
    }
    .search-result-title {
      font-size: 1.5em;
      font-weight: bold;
      display: block;
      margin-bottom: 0;
    }
    .search-result-link {
      color: $gray;
      display: block;
      margin-top: 0;
      font-size: .9em;
    }
    .search-result-preview {
      float: left;
    }
    .search-result-thumb {
      float: right;
    }
  }
}
// MARKET INSIGHTS
.marketinsightsforum {
  .lead {
    padding-bottom: 0;
  }
  .article-cards {
    padding-top: 0;
  }
  .card.normal-style {
    border: 1px solid $light-gray;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
  }
  .article-cards .card figure {
    display: none;
    border: none;
    @include breakpoint(medium) {
      display: block;
    }
  }
  .card-divider {
    padding: 1rem 1rem 0 1rem;
  }
  .card-section {
    padding: 0 1rem 1rem 1rem;
    .button {
      margin-top: .5em;
      span {
        display: none;
      }
    }
  }
  .personal-callout-person {
    text-align: center;
    margin: 2em auto;
    padding: 1em;
    background-color: #fefefe;
    box-shadow: 0 10px 20px -15px rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    p {
      margin: .5em auto;
      line-height: 1em;
    }
    span {
      display: block;
      font-size: .7em;
    }
    a:not(.button) {
      color: $black;
      span {
        color: $medium-gray;
      }
      &:hover {
        color: $secondary-color;
      }
    }
    img {
      border-radius: 100%;
      border: 3px solid $blue;
      max-width: 120px;
      @include breakpoint(medium) {
        max-width: none;
      }
    }
  }
}
// ANIMATIONS
//////////////////////////////////////////////////////////////////////////
.animate {
  opacity: 0;
}
.slideUp {
  animation-name: slideUp;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
@keyframes slideUp {
  0% {
    transform: translateY(20%);
    opacity: 0;
  }
  25% {
    transform: translateY(-2%);
  }
  75% {
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
  }
}
.fadein {
  opacity: 0;
}
.fadeout {
  opacity: 1;
}
.fadein-animation {
  animation-name: fadein-animation;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
@keyframes fadein-animation {
  0% {
    transform: translateY(20%);
    opacity: 0;
  }
  25% {
    transform: translateY(0%);
  }
  75% {
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
  }
}
.fadeout-animation {
  animation-name: fadeout-animation;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
@keyframes fadeout-animation {
  0% {
    transform: translateY(0%);
    opacity: 1;
  }
  25% {
    transform: translateY(0%);
  }
  75% {
    opacity: 0;
    transform: translateY(50%);
  }
  100% {
    opacity: 0;
    transform: translateY(50%);
  }
}
// isotope
.iso-grid {
  .card {
  }
  //.card-top {
  //  height: 100%;
  //  figure {
  //    height: 100%;
  //    -webkit-box-align: center;
  //    -webkit-align-items: center;
  //    -moz-box-align: center;
  //    -ms-flex-align: center;
  //    align-items: center;
  //    display: -webkit-box;
  //    display: -webkit-flex;
  //    display: -moz-box;
  //    display: -ms-flexbox;
  //    display: flex;
  //    -webkit-box-pack: center;
  //    -webkit-justify-content: center;
  //    -moz-box-pack: center;
  //    -ms-flex-pack: center;
  //    justify-content: center;
  //    overflow: hidden;
  //    position: relative;
  //  }
  //}
  //.card-bottom {
  //  display: flex;
  //  -webkit-box-orient: vertical;
  //  -webkit-box-direction: normal;
  //  -webkit-flex-direction: column;
  //  -moz-box-orient: vertical;
  //  -moz-box-direction: normal;
  //  -ms-flex-direction: column;
  //  flex-direction: column;
  //  -webkit-box-flex: 1;
  //  -webkit-flex-grow: 1;
  //  -moz-box-flex: 1;
  //  -ms-flex-positive: 1;
  //  flex-grow: 1;
  //}
  //.card-summary {
  //  -webkit-box-flex: 1;
  //  -webkit-flex-grow: 1;
  //  -moz-box-flex: 1;
  //  -ms-flex-positive: 1;
  //  flex-grow: 1;
  //  line-height: 1.5em;
  //  min-height: 100px;
  //
  //}
  //.card-link {
  //  -webkit-box-direction: normal;
  //}
}
.visible_item {
  display: none;
}
.showMore {
  margin-top: 2em;
}
.quicksearch {
  border: none;
  border-radius: 20px;
  color: $gray;
  background-color: $white;
  border: 1px solid $secondary-color;
  margin: 0;
  padding-left: 1em;
  box-shadow: inset 0 1px 2px rgba($gray, .5);
  &::placeholder {
    color: $medium-gray;
    font-size: .9em;
  }
  &:focus::placeholder {
    color: transparent;
  }
}
///* ---- button ---- */
//
//.button {
//  display: inline-block;
//  padding: 10px 18px;
//  margin-bottom: 10px;
//  background: #EEE;
//  border: none;
//  border-radius: 7px;
//  background-image: linear-gradient( to bottom, hsla(0, 0%, 0%, 0), hsla(0, 0%, 0%, 0.2) );
//  color: #222;
//  font-family: sans-serif;
//  font-size: 16px;
//  text-shadow: 0 1px white;
//  cursor: pointer;
//}
//
//.button:hover {
//  background-color: #8CF;
//  text-shadow: 0 1px hsla(0, 0%, 100%, 0.5);
//  color: #222;
//}
//
//.button:active,
//.button.is-checked {
//  background-color: #28F;
//}
//
//.button.is-checked {
//  color: white;
//  text-shadow: 0 -1px hsla(0, 0%, 0%, 0.8);
//}
//
//.button:active {
//  box-shadow: inset 0 1px 10px hsla(0, 0%, 0%, 0.8);
//}
//
///* ---- button-group ---- */
//
//.button-group:after {
//  content: '';
//  display: block;
//  clear: both;
//}
//
//.button-group .button {
//  float: left;
//  border-radius: 0;
//  margin-left: 0;
//  margin-right: 1px;
//}
//
//.button-group .button:first-child { border-radius: 0.5em 0 0 0.5em; }
//.button-group .button:last-child { border-radius: 0 0.5em 0.5em 0; }
//
///* ---- isotope ---- */
//
//.grid {
//  border: 1px solid #333;
//  width: 600px;
//}
//
///* clear fix */
//.grid:after {
//  content: '';
//  display: block;
//  clear: both;
//}
//
///* ---- .element-item ---- */
//
//.element-item {
//  position: relative;
//  float: left;
//  width: 100px;
//  height: 100px;
//  margin: 5px;
//  padding: 10px;
//  background: #888;
//  color: #262524;
//}
//
//.element-item > * {
//  margin: 0;
//  padding: 0;
//}
//
//.element-item .name {
//  position: absolute;
//
//  left: 10px;
//  top: 60px;
//  text-transform: none;
//  letter-spacing: 0;
//  font-size: 12px;
//  font-weight: normal;
//}
//
//.element-item .symbol {
//  position: absolute;
//  left: 10px;
//  top: 0px;
//  font-size: 42px;
//  font-weight: bold;
//  color: white;
//}
//
//.element-item .number {
//  position: absolute;
//  right: 8px;
//  top: 5px;
//}
//
//.element-item .weight {
//  position: absolute;
//  left: 10px;
//  top: 76px;
//  font-size: 12px;
//}
//
//.element-item.alkali          { background: #F00; background: hsl(   0, 100%, 50%); }
//.element-item.alkaline-earth  { background: #F80; background: hsl(  36, 100%, 50%); }
//.element-item.lanthanoid      { background: #FF0; background: hsl(  72, 100%, 50%); }
//.element-item.actinoid        { background: #0F0; background: hsl( 108, 100%, 50%); }
//.element-item.transition      { background: #0F8; background: hsl( 144, 100%, 50%); }
//.element-item.post-transition { background: #0FF; background: hsl( 180, 100%, 50%); }
//.element-item.metalloid       { background: #08F; background: hsl( 216, 100%, 50%); }
//.element-item.diatomic        { background: #00F; background: hsl( 252, 100%, 50%); }
//.element-item.halogen         { background: #F0F; background: hsl( 288, 100%, 50%); }
//.element-item.noble-gas       { background: #F08; background: hsl( 324, 100%, 50%); }
